<template>
  <div>
    <holidays :id="$route.params.id" :key="$route.params.id" />
  </div>
</template>
<script>
export default {
  components: {
    holidays () { return import('@/components/holidays/holidays.vue') }
  }
}
</script>
